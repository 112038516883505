// 设置 cookie
function setCookie (name, value, day) {
    let oDate = new Date()
    oDate.setDate(oDate.getDate() + day)
    if (day) {
      document.cookie = `${name}=${value};expires=${oDate}`
    } else {
      document.cookie = `${name}=${value}`
    }
}

// 获取 cookie
function getCookie (name) {
    let arr = document.cookie.split('; ')
    for (let i = 0; i < arr.length; i++) {
      let arr2 = arr[i].split('=')
      if (arr2[0] === name) {
        return arr2[1]
      }
    }
    return ''
}

//移除cookie
function removeCookie (name) {
    setCookie(name, 1, -1)
}

// 设置 local
export const setLocalStorage = function(name, value) {
  localStorage.setItem(name, value)
}
// 获取 local
export const getLocalStorage = function(name) {
  return localStorage.getItem(name)
}
// 清除 local
export const removeLocalStorage = function(name) {
  return localStorage.removeItem(name)
}

// 时间转北京时间
function str2date (str) {
  let d = new Date(str)
  let time = d.getFullYear() + "-" + (d.getMonth() + 1).toString().padStart(2,'0') + "-" + d.getDate().toString().padStart(2,'0');
  return time
}
// 时间转北京时间
function str2time (str) {
    let d = new Date(str)
    let time = d.getFullYear() + "-" + (d.getMonth() + 1).toString().padStart(2,'0') + "-" + d.getDate().toString().padStart(2,'0') + " " + d.getHours().toString().padStart(2,'0') + ":" + d.getMinutes().toString().padStart(2,'0') + ":" + d.getSeconds().toString().padStart(2,'0');
    return time
}

function sortByCreateTime (a, b) {
  return b.createTime - a.createTime
}




export {setCookie, getCookie, removeCookie, str2date, str2time, sortByCreateTime}
