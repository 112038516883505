<template>
    <div class="card-detail">
        <template v-if="type === 'user'">
            <div class="card-page-header">
                <div>用户信息</div>
            </div>
            <div class="card-item">
                <div class="card-item-key">用户头像</div>
                <div class="card-item-value">
                    <div class="logo">
                        <img :src="cardDetail.logo" alt="">
                    </div>
                </div>
            </div>
            <div class="card-item">
                <div class="card-item-key">姓名</div>
                <div class="card-item-value">
                    {{cardDetail.name}}
                </div>
            </div>
            <div class="card-item">
                <div class="card-item-key">手机号</div>
                <div class="card-item-value">
                    {{cardDetail.phone}}
                </div>
            </div>
            <div class="card-item">
                <div class="card-item-key">微信号码</div>
                <div class="card-item-value">
                    {{cardDetail.personalWx}}
                </div>
            </div>
            <div class="card-item">
                <div class="card-item-key">所属企业</div>
                <div class="card-item-value">
                    {{cardDetail.companyName}}
                </div>
            </div>
            <div class="card-item">
                <div class="card-item-key">职位</div>
                <div class="card-item-value">
                    {{cardDetail.position}}
                </div>
            </div>
            <div class="card-item">
                <div class="card-item-key">名片访问量</div>
                <div class="card-item-value">
                    {{cardDetail.seeNum}}
                </div>
            </div>
            <div class="card-item">
                <div class="card-item-key">创建时间</div>
                <div class="card-item-value">
                    {{cardDetail.createTime}}
                </div>
            </div>
            <div class="card-item">
                <div class="card-item-key">个人简介</div>
                <div class="card-item-value">
                    <audio :src="cardDetail.soundRecording" controls="controls" />
                    <div>{{cardDetail.briefIntroduction}}</div>
                </div>
            </div>
            <div class="card-item">
                <div class="card-item-key">图片展示</div>
                <div class="card-item-value">
                    <img v-for="(item, index) in cardDetail.photo" :src="item" :key="index" alt="" class="photos">
                </div>
            </div>
        </template>

        <template v-if="type === 'company'">
            <div class="card-page-header">
                <div>企业信息</div>
                <div v-if="!cardDetail.endTime"><span>未授权</span></div>
                <div v-else><span style="font-size: 12px;color: #67757c; margin-right: 0px;">{{cardDetail.createTime }} 到 {{cardDetail.endTime}}</span><span style="font-weight: bold;font-size: 16px;color: #67757c;">
                    {{cardDetail.currentNum}}</span> 人 <span style="margin-right: 80px;">{{cardDetail.endTime}}</span></div>
            </div>
            <div class="card-item">
                <div class="card-item-key">企业LOGO</div>
                <div class="card-item-value">
                    <div class="logo">
                        <img :src="cardDetail.companyLogo" alt="">
                    </div>
                </div>
            </div>
            <div class="card-item">
                <div class="card-item-key">企业名称</div>
                <div class="card-item-value">
                    {{cardDetail.companyName}}
                </div>
            </div>
            <div class="card-item">
                <div class="card-item-key">所属行业</div>
                <div class="card-item-value">
                    {{cardDetail.industryName}}
                </div>
            </div>
            <div class="card-item">
                <div class="card-item-key">企业公告</div>
                <div class="card-item-value">
                    {{cardDetail.companyNotice}}
                </div>
            </div>
            <div class="card-item">
                <div class="card-item-key">企业员工</div>
                <div class="card-item-value">
                    {{cardDetail.allBusinessCardCount}}
                </div>
            </div>
            <div class="card-item">
                <div class="card-item-key">客户人数</div>
                <div class="card-item-value">
                    {{cardDetail.allCustomerCount}}
                </div>
            </div>
            <div class="card-item">
                <div class="card-item-key">创建时间</div>
                <div class="card-item-value">
                    {{cardDetail.createTime}}
                </div>
            </div>
        </template>

        <template v-if="type === 'administrator'">
            <div class="card-page-header" style="margin-top: 30px;">
                <div>管理员信息</div>
            </div>
            <div class="card-item">
                <div class="card-item-key">姓名</div>
                <div class="card-item-value">
                    {{cardDetail.name}}
                </div>
            </div>
            <div class="card-item">
                <div class="card-item-key">职位</div>
                <div class="card-item-value">
                    {{cardDetail.position}}
                </div>
            </div>
            <div class="card-item">
                <div class="card-item-key">手机号码</div>
                <div class="card-item-value">
                    {{cardDetail.phone}}
                </div>
            </div>
            <div class="card-item">
                <div class="card-item-key">微信号</div>
                <div class="card-item-value">
                    {{cardDetail.personalWx}}
                </div>
            </div>
            <div class="card-item">
                <div class="card-item-key">邮箱</div>
                <div class="card-item-value">
                    {{cardDetail.email}}
                </div>
            </div>
        </template>

<!--        <template v-if="type === 'orderDetail'">-->
<!--            <el-form>-->
<!--                <el-form-item label="企业名称">-->
<!--                    {{orderInfo.companyName}}-->
<!--                </el-form-item>-->
<!--                <el-form-item label="短视频总次数">-->
<!--                    {{orderInfo.duration}}-->
<!--                </el-form-item>-->
<!--                <el-form-item label="名片到期时间">-->
<!--                    {{orderInfo.maturityTime}}-->
<!--                </el-form-item>-->
<!--            </el-form>-->
<!--            <el-form>-->
<!--                <el-form-item label="商品类型">-->
<!--                    {{orderInfo.activityTypeText}}-->
<!--                    <span>{{orderInfo.setMeal}}</span>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="订单编号">-->
<!--                    <span>{{orderInfo.orderNum}}</span>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="下单时间">-->
<!--                    <span>{{orderInfo.orderTime}}</span>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="支付方式">-->
<!--                    <span>{{orderInfo.payType}}</span>-->
<!--                </el-form-item>-->
<!--            </el-form>-->
<!--        </template>-->

    </div>
</template>
<script>
export default {
    props: {
        type: {
            type: String,
            defult: ''
        },
        cardDetail: {
            type: Object,
            defult: {}
        },
        // orderInfo:{
        //     type:Object,
        //     default:{}
        // }
    },
    data () {
        return {

        }
    }
}
</script>
<style lang="scss">
.card-detail{
    width: 100%;
    background: #fff;
    padding: 0 30px;
    .card-page-header{
        height: 80px;
        line-height: 80px;
        border-bottom: 1px solid #f1f2f3;
        font-size:14px;
        color:rgba(103,117,124,1);
        display: flex;
        justify-content: space-between;
        span{
            font-size:14px;
            font-weight:400;
            color:rgba(81,205,203,1);
        }
    }
    .card-item{
        display: flex;
        padding: 17px 0;
        background: #fff;
        .card-item-key{
            width: 80px;
            font-size:14px;
            color:rgba(103,117,124,1);
            line-height: 24px;
        }
        .card-item-value{
            flex: 1;
            line-height: 24px;
            font-size:14px;
            color:rgba(38,50,56,1);
            .logo{
                width: 50px;
                height: 50px;
                border-radius: 50%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: 50px;
                }
            }
            .photos{
                width: 120px;
                height: 120px;
                margin: 0 10px 10px 0;
            }
        }
    }
}
</style>
