<template>
  <div class="card-page">
    <div class="card-detail">
      <div class="back_btn" @click="back">&lt;&nbsp;&nbsp;返回</div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="企业详情" name="1">
          <div class="card-box">
<!--            <div class="setAgent" v-if="cardDetail.isAgent == 1">-->
<!--              <div class="isAgent">代理商</div>-->
<!--              &lt;!&ndash; <div class="btn setPrice" v-if="!cardDetail.agentMoney || cardDetail.agentMoney == 0" @click="setagentMoney">设置代理价格</div> &ndash;&gt;-->
<!--              <div class="btn price">{{cardDetail.agentMoney / 100}}元/张</div>-->
<!--            </div>-->
            <!-- <div class="setAgent" v-else>
                <div class="btn" @click="setAgent(1)">设置成为代理商</div>
            </div> -->
            <CardDetail :cardDetail="cardDetail" :type="'company'"></CardDetail>
            <CardDetail :cardDetail="cardDetail" :type="'administrator'"></CardDetail>
          </div>
        </el-tab-pane>
<!--        <el-tab-pane v-if="cardDetail.isAgent == 1" label="代理企业列表" name="2">-->
<!--          <div class="card-box">-->
<!--            <el-table-->
<!--                :data="companyList"-->
<!--                border-->
<!--                tooltip-effect="dark"-->
<!--                style="width: 100%; height: calc(100vh - 280px)"-->
<!--            >-->
<!--              <el-table-column-->
<!--                  label="编号"-->
<!--                  width="60">-->
<!--                <template slot-scope="scope">-->
<!--                  <div>{{ scope.row.companyId }}</div>-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--              <el-table-column-->
<!--                  prop="companyName"-->
<!--                  label="企业名称"-->
<!--                  min-width="280">-->
<!--              </el-table-column>-->
<!--              <el-table-column-->
<!--                  prop="allBusinessCardCount"-->
<!--                  width="80"-->
<!--                  label="企业员工">-->
<!--              </el-table-column>-->
<!--              <el-table-column-->
<!--                  prop="currentNum"-->
<!--                  width="80"-->
<!--                  label="总名片数">-->
<!--              </el-table-column>-->
<!--              <el-table-column-->
<!--                  prop="availableNum"-->
<!--                  width="100"-->
<!--                  label="未用名片数">-->
<!--              </el-table-column>-->
<!--              <el-table-column-->
<!--                  label="客户人数"-->
<!--                  width="80">-->
<!--                <template slot-scope="scope">-->
<!--                  <div>{{ scope.row.allCustomerCount }}</div>-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--              <el-table-column-->
<!--                  prop="name"-->
<!--                  label="管理员"-->
<!--                  width="120">-->
<!--              </el-table-column>-->
<!--              <el-table-column-->
<!--                  prop="phone"-->
<!--                  width="120"-->
<!--                  label="管理员电话">-->
<!--              </el-table-column>-->
<!--              <el-table-column-->
<!--                  label="微信号码"-->
<!--                  width="120">-->
<!--                <template slot-scope="scope">-->
<!--                  <div>{{ scope.row.personalWx }}</div>-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--              <el-table-column-->
<!--                  label="创建时间"-->
<!--                  width="140">-->
<!--                <template slot-scope="scope">-->
<!--                  <div>{{ scope.row.createTime | formatDateMin }}</div>-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--              <el-table-column-->
<!--                  label="到期时间"-->
<!--                  align="center"-->
<!--                  width="140">-->
<!--                <template slot-scope="scope">-->
<!--                  <div>{{ scope.row.endTime | formatDateMin }}</div>-->
<!--                  <div style="color: #FF5C6C;">{{ scope.row | getStatus }}</div>-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--              <el-table-column-->
<!--                  label="操作"-->
<!--                  width="100">-->
<!--                <template slot-scope="scope">-->
<!--                  <div style="text-align: center;">-->
<!--                    &lt;!&ndash; <el-button class="deleteCard" disabled @click="deleteCard(scope.row.a)" type="text" size="medium">删除</el-button> &ndash;&gt;-->
<!--                    <el-button class="checkCard" @click="checkCard(scope.row.companyId)" type="text" style="color: #51cdcb;" size="medium">详情</el-button>-->
<!--                    <el-button class="checkCard" @click="setSermissions(scope.row)" type="text" size="medium">设置</el-button>-->
<!--                  </div>-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--            </el-table>-->
<!--            <div class="page">-->
<!--              <el-pagination-->
<!--                  @current-change="handleCurrentChange"-->
<!--                  :current-page.sync="pageNum"-->
<!--                  :page-sizes="[10]"-->
<!--                  :page-size="10"-->
<!--                  layout="total, sizes, prev, pager, next, jumper"-->
<!--                  :total="total">-->
<!--              </el-pagination>-->
<!--            </div>-->
<!--          </div>-->
<!--        </el-tab-pane>-->
      </el-tabs>
    </div>
    <div class="img-box">
      <div>
        <img :src="codeImg" alt="">
        <p>打开微信扫一扫</p>
        <p>立即预览用户名片信息</p>
      </div>
    </div>
    <el-dialog title="权限管理" :visible.sync="dialogVisible" class="customer-manage-dialog">
      <el-form
          :model="setSermissionsform"
          label-width="100px"
          inline
          label-position="left">
        <el-form-item label="当前企业">
          <!-- <el-input v-model="setSermissionsform.companyName" auto-complete="off"></el-input> -->
          <div>{{setSermissionsform.companyName}}</div>
        </el-form-item>
        <br>
        <el-form-item label="管理员">
          <!-- <el-input v-model="setSermissionsform.administrator" auto-complete="off"></el-input> -->
          <div>{{setSermissionsform.administrator}}</div>
        </el-form-item>
        <br>
        <el-form-item label="总购买数">
          <el-input v-model="setSermissionsform.purchaseNum" auto-complete="off"></el-input>
        </el-form-item>
        <br>
        <el-form-item label="支付金额">
          <el-input v-model="setSermissionsform.payNum" auto-complete="off"></el-input>
        </el-form-item>
        <br>
        <el-form-item label="截止日期">
          <el-date-picker
              v-model="setSermissionsform.time"
              :picker-options="pickerOptions"
              type="date"
              format="yyyy-MM-dd"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <br>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import CardDetail from "@/components/card-detail.vue"
import { getCompanyInfoByKey, addCompanyServiceApi, configureAgenApi, selectAgentCompanyListApi } from '@/api/members';
import { str2date } from '@/utils/util.js';
export default {
  components: { CardDetail },
  data () {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      companyList: [],
      pageNum: 1,
      total: 0,
      codeImg: '',
      activeName: '1',
      cardDetail: {
        agentMoney: '',
        companyLogo: '',
        companyName: '',
        industryName: '',
        companyNotice: '',
        allBusinessCardCount: '',
        allCustomerCount: '',
        createTime: '',
        name: '',
        position: '',
        phone: '',
        personalWx: '',
        email: '',
        isAgent: ''
      },
      dialogVisible: false,
      setSermissionsform: {
        companyName: '',
        administrator: '',
        companyId: '',
        purchaseNum: '',
        payNum: '',
        time: ''
      }
    }
  },
  created() {
    this.getCardDetail();
  },
  mounted() {

  },
  watch:{
    '$route':'fetchData'
  },
  methods: {
    fetchData(){
      this.getCardDetail();
    },
    confirm () {
      let params = {
        companyId: this.setSermissionsform.companyId,
        purchaseNum: this.setSermissionsform.purchaseNum,
        payNum: (Number(this.setSermissionsform.payNum) * 100).toFixed(0),
        endTime : str2date(this.setSermissionsform.time),
      }
      addCompanyServiceApi(params).then(res => {
        if (res.code === 201) {
          this.$message({
            message: res.message,
            type: 'warning'
          });
          return ;
        }
        this.dialogVisible = false;
        this.setSermissionsform = {};
        this.selectAgentCompanyList();
      })
    },
    checkCard (id) {
      this.$router.push({path: "/companyCardPage", query: {id: id}})
      location.reload();
    },
    setSermissions (item) {
      let nowTime = str2date(new Date());
      let endTime = item.endTime ? str2date(item.endTime) : '' ;
      this.setSermissionsform = {
        companyName: item.companyName,
        administrator: item.name,
        companyId: item.companyId,
        purchaseNum: '',
        payNum: '',
        time : nowTime > endTime ? nowTime : endTime
      };
      this.dialogVisible = true ;
    },
    handleCurrentChange(){
      this.selectAgentCompanyList();
    },
    setagentMoney(){
      this.$prompt('', '输入代理价', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        let params = {
          companyId: this.$route.query.id,
          isAgent: this.cardDetail.isAgent,
          agentMoney: (value * 100).toFixed(0)
        }
        configureAgenApi(params).then(res => {
          if (res.code == 200) {
            this.cardDetail.agentMoney = params.agentMoney;
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }
        })
      }).catch(() => {

      });
    },
    setAgent(isAgent){
      if (isAgent == 0) {
        this.$confirm('是否取消代理商资格?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.configureAgen(isAgent);
          this.cardDetail.agentMoney = 0;
        }).catch(() => {

        });
      } else {
        this.configureAgen(isAgent);
      }
    },
    configureAgen(isAgent){
      let params = {
        companyId: this.$route.query.id,
        isAgent: isAgent
      };
      configureAgenApi(params).then(res => {
        if (res.code == 200) {
          this.cardDetail.isAgent = isAgent;
        } else {
          this.$message({
            message: res.message,
            type: 'warning'
          });
        }
      })
    },
    handleClick(){
      if (this.companyList.length == 0 && this.activeName == '2') {
        this.selectAgentCompanyList();
      }
    },
    selectAgentCompanyList(){
      let params = {
        pageNum: this.pageNum,
        companyId: this.$route.query.id
      }
      selectAgentCompanyListApi(params).then(res => {
        if (res.code == 200) {
          this.companyList = res.data.pageInfo.list;
          this.total = res.data.pageInfo.total;
        } else {
          this.$message({
            message: res.message,
            type: 'warning'
          });
        }
      })
    },
    getCardDetail () {
      let params = {
        companyId: this.$route.query.companyId
      }
      getCompanyInfoByKey(params).then(res => {
        let cardDetail = res.data
        if (cardDetail.photo) {
          cardDetail.photo = cardDetail.photo.split(',');
        }
        if (!cardDetail.agentMoney) {
          cardDetail.agentMoney = 0;
        }
        cardDetail.createTime = this.renderTime(cardDetail.createTime)
        cardDetail.endTime = this.renderTime(cardDetail.endTime)
        this.cardDetail = cardDetail;
        this.codeImg = res.data.wxTwoCode
      })
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    back(){
      window.history.back();
    }
  },
}
</script>
<style lang="scss">
.card-page{
  display: flex;
  .card-detail{
    position: relative;
  }
  .card-box{
    flex: 1;
    min-width: 900px;
    position: relative;
    .deleta-btn{
      padding: 20px 0;
      div{
        height: 40px;
        line-height: 40px;
        text-align: center;
        background:rgba(255,92,108,1);
        border-radius:4px;
        color:#fff;
        cursor: pointer;
        width: 85px;
      }
    }
    .setAgent{
      position: absolute;
      right: 70px;
      top: 120px;
      z-index: 999;
      .isAgent{
        width: 120px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 5px;
        background: #fcb46c;
        border-radius: 5px;
        border: solid #fcb46c 1px;
        color: #fff;
        font-weight: bold;
        margin-bottom: 30px;
      }
      .btn{
        width: 120px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 5px;
        border: solid #51CDCB 1px;
        background: #51CDCB;
        color: #fff;
      }
      .price{
        font-weight: bold;
      }
    }
    .page{
      padding: 10px 0;
      text-align: center;
    }
  }
  .img-box{
    margin-left: 128px;
    min-width: 466px;
    width: 466px;
    height: 950px;
    background: url(../../assets/images/iphone.png) no-repeat 0 0;
    background-size: 100% 100%;
    padding: 198px 40px 118px 40px;
    box-sizing: border-box;
    div{
      width: 100%;
      height: 100%;
      position: relative;
      background: #fff;
      padding-top: 2px;
      img{
        width: 300px;
        height: 300px;
        margin-left: 43px;
        margin-bottom: 40px;
      }
      p{
        height:41px;
        font-size:16px;
        color:rgba(38,50,56,1);
        line-height:24px;
        text-align: center;
        margin: 0;
      }
    }
  }
  .back_btn{
    position: absolute;
    border-radius: 4px;
    // background: #B9EBEA;
    background: #51CDCB;
    font-size: 14px;
    line-height: 30px;
    height: 30px;
    color: #fff;
    padding: 0 20px;
    cursor: pointer;
    top: 5px;
    right: 30px;
    z-index: 999;
  }
}
</style>
